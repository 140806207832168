import React, {ChangeEvent, useState} from "react";
import ReactDOM from "react-dom";
import {Button, Form, Input, message, Modal, Select, Spin, Tooltip} from "antd";
import Icon from '@ant-design/icons';
import {ensure} from "../../common/utils";
import paypalLogo1 from "../../images/paypal-logo1.svg";
import paypalLogo2 from "../../images/paypal-logo2.svg";
import {usePost} from "../../common/hooks";
import {Discount, Division, PlayerType, RegistrationPlayer} from "../../common/interfaces";
import _ from "lodash";

declare const paypal: any;

const PLAYER_TYPE_TO_TOTAL: { [key: number]: number } = {
    [PlayerType.SKATER]: 650,
    [PlayerType.GOALIE]: 200,
    [PlayerType.SKATER_AND_GOALIE]: 700
};

interface AppliedDiscount extends Discount {
    subTotalBeforeDiscount: number;
    appliedDiscountAmt: number;
}

interface RequestableDivisions {
    divisionBelow?: Division;
    currentDivision: Division;
    divisionAbove?: Division;
}

export function RegistrationCheckout(props: {
    player?: RegistrationPlayer;
    autoDiscounts: Discount[];
    seasonId: string;
    postReqIsLoading: boolean;
    onPayment: (data: any) => void;
}) {
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [address, setAddress] = useState<string>(props.player ? props.player.address : "");
    const [city, setCity] = useState<string>(props.player ? props.player.city : "");
    const [zip, setZip] = useState<string>(props.player ? props.player.zip : "");
    const [dateOfBirth, setDateOfBirth] = useState<string>(props.player ? props.player.dateOfBirth : "");
    const [usaHockeyNumber, setUsaHockeyNumber] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>(props.player ? props.player.phoneNumber : "");
    const [promoCode, setPromoCode] = useState<string>("");
    const [promoCodeDiscount, setPromoCodeDiscount] =
        useState<Discount | undefined>();
    const [division, setDivision] = useState<Division>(
        props.player ? props.player.division : Division.UNKNOWN
    );
    const [playerType, setPlayerType] = useState<PlayerType>(props.player ? props.player.playerType : PlayerType.SKATER);
    const [paypalIsCapturing, setPaypalIsCapturing] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [agreesToWaiver, setAgreesToWaiver] = useState<boolean>(false);

    const promoPostReq = usePost({uri: "/api/registration/promo"});

    const divisionInfo = eligibleTryoutDivisions(props.player);

    const subtotal = PLAYER_TYPE_TO_TOTAL[playerType];
    const autoAmtDiscounts = props.autoDiscounts.filter(discount => discount.amtOff > 0) as Discount[];
    const autoPctDiscount = props.autoDiscounts.find(discount => discount.pctOff > 0);

    const appliedDiscounts = [] as AppliedDiscount[];
    let curSubtotal = PLAYER_TYPE_TO_TOTAL[playerType];
    for (let i = 0; i < autoAmtDiscounts.length; i++) {
        const appliedDiscount = getAppliedDiscount(autoAmtDiscounts[i], curSubtotal);
        appliedDiscounts.push(appliedDiscount);
        curSubtotal -= appliedDiscount.appliedDiscountAmt;
    }
    if (autoPctDiscount !== undefined) {
        const appliedDiscount = getAppliedDiscount(autoPctDiscount!, curSubtotal);
        appliedDiscounts.push(appliedDiscount);
        curSubtotal -= appliedDiscount.appliedDiscountAmt;
    }
    if (promoCodeDiscount !== undefined) {
        const appliedDiscount = getAppliedDiscount(promoCodeDiscount!, curSubtotal);
        appliedDiscounts.push(appliedDiscount);
        curSubtotal -= appliedDiscount.appliedDiscountAmt;
    }
    const finalTotal = curSubtotal;

    const onPayment = (data: {
        orderId?: string;
        payerId?: string;
    }) => {
        props.onPayment({
            ...data,
            firstName,
            lastName,
            address,
            city,
            zip,
            phoneNumber,
            dateOfBirth,
            division,
            playerType,
            usaHockeyNumber,
            discountIdsUsed: appliedDiscounts.map(ad => ad.id),
            total: finalTotal,
            needsEval:
                !props.player ||
                props.player.needsEval ||
                division > props.player.division
        });
    };

    const requiredFields = [
        address,
        city,
        zip,
        phoneNumber,
        usaHockeyNumber,
        dateOfBirth,
        agreesToWaiver
    ];

    if (!props.player) {
        requiredFields.push(firstName);
        requiredFields.push(lastName);
    }

    const formIsInvalid = requiredFields.some(field => !field);

    const formItemLayout = {
        labelCol: {span: 4},
        wrapperCol: {span: 18}
    };

    const noLabelFormItemLayout = {
        wrapperCol: {
            span: formItemLayout.wrapperCol.span,
            offset: formItemLayout.labelCol.span
        }
    };

    const lineHeight = "20px";

    return (
        <>
            <Form layout={"horizontal"}>
                {!props.player && (
                    <>
                        <Form.Item label={"First name"} {...formItemLayout}>
                            <WatchableInput state={firstName} setState={setFirstName}/>
                        </Form.Item>
                        <Form.Item label={"Last name"} {...formItemLayout}>
                            <WatchableInput state={lastName} setState={setLastName}/>
                        </Form.Item>
                    </>
                )}
                <Form.Item label={"Street address"} {...formItemLayout}>
                    <WatchableInput state={address} setState={setAddress}/>
                </Form.Item>
                <Form.Item label={"City and state"} {...formItemLayout}>
                    <WatchableInput state={city} setState={setCity}/>
                </Form.Item>
                <Form.Item label={"ZIP code"} {...formItemLayout}>
                    <WatchableInput state={zip} setState={setZip}/>
                </Form.Item>
                <Form.Item label={"Phone number"} {...formItemLayout}>
                    <WatchableInput state={phoneNumber} setState={setPhoneNumber}/>
                </Form.Item>
                <Form.Item label={"Date of birth"} {...formItemLayout}>
                    <WatchableInput
                        state={dateOfBirth}
                        setState={setDateOfBirth}
                        type={"date"}
                    />
                </Form.Item>
                <Form.Item
                    label={usaHockeyLink}
                    extra={
                        "This must be a valid USA Hockey number for the current season."
                    }
                    {...formItemLayout}
                >
                    <WatchableInput
                        state={usaHockeyNumber}
                        setState={setUsaHockeyNumber}
                    />
                </Form.Item>
                {props.player && !!divisionInfo && (
                    <Form.Item label={"Division"} {...formItemLayout}>
                        <Select
                            value={division}
                            onChange={v => setDivision(v)}
                            style={{width: 300}}
                        >
                            {!!divisionInfo.divisionBelow && (<Select.Option
                                value={divisionInfo.divisionBelow}
                                key={divisionInfo.divisionBelow}>
                                {`Move down to ${_.capitalize(Division[divisionInfo.divisionBelow])}`}
                            </Select.Option>)}
                            <Select.Option key={divisionInfo.currentDivision} value={divisionInfo.currentDivision}>
                                {`Remain in ${_.capitalize(Division[divisionInfo.currentDivision])}`}
                            </Select.Option>
                            {!!divisionInfo.divisionAbove && (<Select.Option
                                value={divisionInfo.divisionAbove}
                                key={divisionInfo.divisionAbove}>
                                {`Try out for ${_.capitalize(Division[divisionInfo.divisionAbove])}`}
                            </Select.Option>)}
                        </Select>
                        {props.player.needsEval && (
                            <div>
                                <Icon type={"warning"} style={{color: "red"}}/> You've been
                                inactive for more than one season and need to be re-evaluated
                            </div>
                        )}
                    </Form.Item>
                )}
                <Form.Item label={"Position"} {...formItemLayout}>
                    <PlayerTypeSelect
                        value={playerType}
                        onChange={(val: number) => setPlayerType(val)}
                    />
                </Form.Item>
            </Form>
            <Form.Item label={"Promo code"} {...formItemLayout}>
                <WatchableInput state={promoCode} setState={setPromoCode}/>
                <Button
                    type={"primary"}
                    loading={promoPostReq.isLoading}
                    onClick={() => {
                        promoPostReq.send(
                            {promoCode, seasonId: props.seasonId, playerType},
                            (discount: Discount) => {
                                setPromoCodeDiscount(discount);
                                message.success("Promotion applied.");
                                setPromoCode("");
                            }
                        );
                    }}
                    style={{marginLeft: 10}}
                >
                    Apply
                </Button>
            </Form.Item>
            <Form.Item {...noLabelFormItemLayout}>
                <Icon
                    type={agreesToWaiver ? "check" : "warning"}
                    style={{
                        color: agreesToWaiver ? "green" : "red",
                        paddingRight: 5,
                        fontSize: "16px"
                    }}
                />
                Please read and agree to the{" "}
                <Button
                    type={"link"}
                    style={{paddingLeft: 0}}
                    onClick={() => setModalVisible(true)}
                >
                    NCWHL waiver
                </Button>
            </Form.Item>
            <Form.Item {...noLabelFormItemLayout}>
                {appliedDiscounts.length > 0 && (
                    <div style={{lineHeight}}>
                        <strong>Subtotal:</strong> ${subtotal}
                    </div>
                )}
                {(appliedDiscounts
                    .map(discount => getDiscountLine(discount)))}
                <div style={{lineHeight}}>
                    <strong>Total:</strong> ${finalTotal}
                </div>
            </Form.Item>
            <Form.Item {...noLabelFormItemLayout}>
                <Tooltip
                    title={
                        formIsInvalid
                            ? "Make sure all the fields are filled out and you have agreed to the waiver."
                            : undefined
                    }
                    placement={"topLeft"}
                >
                    <div style={{overflow: "hidden"}}>
                        <div style={{width: 300, height: 45, float: "left"}}>
                            {(() => {
                                if (formIsInvalid) {
                                    return <DisabledPaypalButton/>;
                                } else if (finalTotal === 0) {
                                    return (
                                        <Button
                                            type={"primary"}
                                            style={{width: "100%", height: "100%"}}
                                            onClick={() => onPayment({})}
                                            loading={props.postReqIsLoading}
                                        >
                                            Register
                                        </Button>
                                    );
                                } else {
                                    return (
                                        <PaymentButton
                                            paymentValue={finalTotal}
                                            onPayment={onPayment}
                                            setIsCapturing={setPaypalIsCapturing}
                                        />
                                    );
                                }
                            })()}
                        </div>
                        <div style={{float: "left"}}>
                            {finalTotal > 0 &&
                                (paypalIsCapturing || props.postReqIsLoading) && (
                                    <Spin style={{padding: 12}}/>
                                )}
                        </div>
                    </div>
                </Tooltip>
            </Form.Item>
            <Modal
                visible={modalVisible}
                onOk={() => {
                    setAgreesToWaiver(true);
                    setModalVisible(false);
                }}
                onCancel={() => setModalVisible(false)}
                okText={"Agree"}
                title={"NCWHL Waiver"}
            >
                {WAIVER_TEXT}
            </Modal>
        </>
    );
}

function getDiscountLine(discount: AppliedDiscount) {
    if (discount.amtOff > 0) {
        return (
            <div style={{lineHeight: "20px"}}>
                <strong>{`${discount.description}: `}</strong>
                {`-$${Math.round(discount.appliedDiscountAmt)}`}
            </div>
        );
    }

    return (
        <div style={{lineHeight: "20px"}}>
            <strong>{`${discount.description}: `}</strong>
            {`-$${Math.round(discount.appliedDiscountAmt)}`}
            {`(${discount.pctOff}% `}<span>&#215;</span>{` ${discount.subTotalBeforeDiscount})`}
        </div>
    );
}

function getAppliedDiscount(discount: Discount, curSubTotal: number) {
    return {
        ...discount,
        subTotalBeforeDiscount: curSubTotal,
        appliedDiscountAmt: getDiscountAmount(discount, curSubTotal)
    } as AppliedDiscount;
}

function getDiscountAmount(discount: Discount, amount: number) {
    if (discount.amtOff > 0) {
        return discount.amtOff;
    }

    // Round to nearest penny
    return Math.round((discount.pctOff * amount / 100) * 100) / 100;
}

function PaymentButton(props: {
    paymentValue: number;
    onPayment: (data: { payerId: string; orderId: string }) => void;
    setIsCapturing: (loading: boolean) => void;
}) {
    const PaypalButton = paypal.Buttons.driver("react", {React, ReactDOM});

    const createOrder = (data: any, actions: any) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: props.paymentValue
                    }
                }
            ]
        });
    };

    const onApprove = (data: any, actions: any) => {
        props.setIsCapturing(true);
        actions.order
            .capture()
            .then(() => {
                props.setIsCapturing(false);
                props.onPayment({payerId: data.payerID, orderId: data.orderID});
            })
            .catch((err: string) => message.error(err));
    };

    return <PaypalButton createOrder={createOrder} onApprove={onApprove}/>;
}

function PlayerTypeSelect(props: {
    value: PlayerType;
    onChange: (val: PlayerType) => void;
}) {
    return (
        <Select
            style={{width: 300}}
            value={props.value}
            onChange={props.onChange}
        >
            <Select.Option key={PlayerType.SKATER} value={PlayerType.SKATER}>
                Skater
            </Select.Option>
            <Select.Option key={PlayerType.GOALIE} value={PlayerType.GOALIE}>
                Goalie
            </Select.Option>
            <Select.Option
                key={PlayerType.SKATER_AND_GOALIE}
                value={PlayerType.SKATER_AND_GOALIE}
            >
                Skater & Goalie
            </Select.Option>
        </Select>
    );
}

function WatchableInput(props: {
    state: any;
    setState: (v: any) => void;
    type?: string;
}) {
    return (
        <Input
            style={{width: 300}}
            value={props.state}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                props.setState(e.target.value)
            }
            type={props.type}
        />
    );
}

function DisabledPaypalButton() {
    return (
        <Button disabled={true} style={{width: "100%", height: "100%"}}>
            <img
                style={{height: 21, paddingRight: 4}}
                src={paypalLogo1}
                alt={"PayPal"}
            />
            <img style={{height: 21}} src={paypalLogo2} alt={"PayPal"}/>
        </Button>
    );
}

const eligibleTryoutDivisions = (player?: RegistrationPlayer): RequestableDivisions | undefined => {
    if (!player) {
        return undefined;
    }

    switch (player.division) {
        case Division.UNKNOWN:
            return undefined;
        case Division.GREEN:
            return {currentDivision: Division.GREEN, divisionAbove: Division.RED};
        case Division.RED:
            return {divisionBelow: Division.GREEN, currentDivision: Division.RED, divisionAbove: Division.MAROON};
        case Division.MAROON:
            return {divisionBelow: Division.RED, currentDivision: Division.MAROON, divisionAbove: Division.BLUE};
        case Division.BLUE:
            return {divisionBelow: Division.MAROON, currentDivision: Division.BLUE};
        default:
            console.error(`Unrecognized division: ${player.division}`);
            return undefined;
    }
};

const usaHockeyLink = (
    <a href={"https://membership.usahockey.com/"} target={"_blank"}>
        USA Hockey
    </a>
);

const WAIVER_TEXT = (
    <div>
        <p>
            For and in consideration of participant's involvement with the Northern
            California Women's Hockey League (NCWHL), and being allowed to participate
            in NCWHL events, the participant relinquishes any and all liability for
            and cause of action for personal injury, property damage or wrongful death
            occurring to participant arising out of participation in the NCWHL.
        </p>
        <p>
            Participant acknowledges, understands, and assumes all risks inherent in
            ice hockey, and understands that said sports and activities involve risks
            to participant's person including bodily injury, partial or total
            disability, paralysis, and death, and damages that may arise therefrom and
            that I have full knowledge of said risks. These risks and dangers may be
            caused by the negligence of the participant or the negligence of others,
            including the "releasees" identified below. It is further acknowledged
            that there may be risks and dangers not known to us or are not reasonably
            foreseeable at this time.
        </p>
        <p>
            It is the purpose of this agreement to exempt, waive, and relieve
            releasees from liability for personal injury, property damage, and
            wrongful death caused by negligence, including the negligence, if any, of
            releasees. "Releasees" include NCWHL, its members, coaches, and
            participants, and operators of the premises used to conduct the event.
        </p>
        <p>
            I hereby grant the Northern California Women's Hockey League permission to
            use my likeness in a photograph or video in any and all of its
            publications, including website entries, without payment or any other
            consideration.
        </p>
    </div>
);
